<template>
  <v-card class="pa-2">
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="jornada.name"
            label="Nombre"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="jornada.espacio"
            label="Espacio"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="jornada.personas"
            label="Personas"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-subheader>Inicio y fin de la jornada</v-subheader>
          <v-divider class="mb-2"></v-divider>
          <v-date-picker
            :first-day-of-week="1"
            full-width
            filled
            dense
            hide-details="auto"
            color="primary"
            range
            v-model="jornada.range"
            :rules="[rules.req]"
          ></v-date-picker>
          <span v-if="jornada.range.length == 0" class="error--text">
            <v-icon small left color="error">mdi-alert-outline</v-icon>
            Este campo es obligatorio
          </span>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="jornada.idJornada" @click.stop="$emit('reload')">
							<v-icon left>mdi-close</v-icon>
												Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ jornada.idJornada ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="confirm" />

  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
  },
	components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
	},
  data() {
    return {
      jornada: {
        ...this.value,
        range: [this.value.start, this.value.end].filter((x) => x),
      },
      rules: {
        req,
      },
    };
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

			const confirmation = await this.$refs.confirm.open(
				`Jornada: ${this.jornada.name}`,
        `¿Estás seguro de que quieres ${this.jornada.idJornada ? "actualizar" : "crear"} la jornada?`,
        { color: "primary" }
      );
      if (!confirmation) return;


      await axios({
        method: this.jornada.idJornada ? "PUT" : "POST",
        url: `/jornadas/${String(
          this.jornada.idJornada ? this.jornada.idJornada : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.jornada,
          start: this.jornada.range[0],
          end: this.jornada.range[1] || this.jornada.range[0],
        },
      });

      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>